<template>
    <Layout id="layouttttttttttt">
      <div class="row mt-2">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="accueil" v-if="AddingInvoice">
                <div style="text-align: left;padding: 2rem;">
                  <button @click="AddingInvoice = false" type="button" class="btn btn-outline btn-info mb-3"><span
                      class="btn-label"><i class="mdi mdi-arrow-left-bold-box-outline" style="transform: scale(1.7);"></i>
                    </span>Go back </button>
                </div>
                <form @submit.prevent="preventsubmit">
                  
                                    <b-form-group class="text-left" label="reference" label-for="reference">
                                        <b-form-input type="text" id="reference"
                                        placeholder="123456789" v-model="form.Reference" ></b-form-input>
                                    </b-form-group>
                               

                                    <b-form-group class=" text-left" label="Supplier" label-for="Suppliers">
                                        <multiselect v-model="form.Supplier" id="Suppliers" :options="Suppliers"/>
                                    </b-form-group>


                                <b-form-group class="text-left" label="amount" label-for="amount">
                                    <b-form-input type="number" id="amount"
                                        placeholder="99999"
                                        v-model="form.Amount"
                                        ></b-form-input>
                                </b-form-group>
                                <div class="form-row">
                                    <b-form-group class="col-6 text-left" label="Regulation Date" label-for="Regulation_Date">
                                        <b-form-input type="date" id="Regulation_Date" 
                                           v-model="form['Regulation Date']" ></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="col-6 text-left" label="deadline date" label-for=" deadline_date">
                                        <b-form-input type="date" id=" deadline_date" 
                                           v-model="form['Deadline Date']" ></b-form-input>
                                    </b-form-group>
                                  </div>

                                <div class="mt-5 mb-5 text-center text-left">
                                    <button type="submit" @click="handleSumbit"
                                        class="btn w-25 btn-outline-primary btn-bordered-primary">Add</button>
                                </div>
                            </form>
                <div class="form-container pl-4 pr-4">
                  <h2 style="margin-bottom: 20px">Add regulation</h2>
                  <span> </span>
  
                  <div>
                    <button class="file-upload-button" @click="selectAndUploadFile">
                      Import
                    </button>
  
                    <div v-if="uploadedFileUrl">
                      <div style="display: flex; justify-content: space-around">
                        <div class="uploaded-file">
                          <img v-if="isImage" id="imgOCR" :src="uploadedFileUrl" alt="Uploaded Image"
                            class="uploaded-image" />
                          <div v-for="(word, index) in recognizedWords" :key="`word_${index}`" class="word"
                            style="position: absolute;"
                            :style="{ left: word.style.left + '!important', top: word.style.top + '!important' }">
                            {{ word.text }}
                          </div>
                          <embed v-if="!isImage" :src="uploadedFileUrl" type="application/pdf" width="100%" height="500px"
                            class="uploaded-pdf" />
                        </div>
  
                        <div style="width: 30%" v-if="uploadedFileUrl" class="uploaded-file">
                          <div class="word-container">
                            <span v-for="(Splitted_Words_from_text_word, index) in Splitted_Words_from_text"
                              :key="`Splitted_Words_${index}`" class="draggable-word" :draggable="true"
                              @dragstart="onDragStart(index, $event)">
                              {{ Splitted_Words_from_text_word }}
                            </span>
                          </div>
                        </div>
  

                      </div>
                    </div>
                  </div>
  
                  <!-- Loading spinner -->
                  <div v-if="isLoading" class="loading-spinner">
                    <div class="spinner"></div>
                    <p>Loading...</p>
                  </div>
                </div>
              </div>
  
              <div v-else>
                <p class="text-muted font-13 mb-4"></p>
                <div class="row mb-md-4">
                  <div class="col-sm-12 col-md-4">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-4">
                    <label class="d-inline-flex align-items-center">Add new regulation</label>
                    <button type="button" class="btn btn-primary ml-2" @click="AddingInvoice = true"><i
                        class="ri-play-list-add-line"></i></button>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-4">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                          class="form-control form-control-sm ml-2"></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive table-striped mb-0">
                  <b-table style="padding: .5rem;" :items="listeRglement" :fields="fields" responsive="sm"
                    :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                    :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered"></b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
  
              </div>
              <!-- end card-box -->
            </div>
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
    </Layout>
  </template>
  
  <script>
  import Layout from "./layouts/main";
  import axios from "axios"
  import swal from "sweetalert"
  import Multiselect from "vue-multiselect";

  /**
   * Invoice component
   */
  export default {
    page: {
      title: "Invoice",
    },
    data() {
      return {

        suppliers:[],

        form:{
          Reference:'',
          Amount:null,
          'Regulation Date':'',
          'Deadline Date':'',
          Supplier:''
        },



        listeRglement: [],
        AddingInvoice: false,
        recognizedWords: {},
        TextfromImage: [],
  
        isOpen: false,
        Splitted_Words_from_text: [],
  
        words: ["Word2", "Word3", "Word4", "Word5", "Word6", "Word7", "Word8"],
  
        InvoiceVariables: {
          "Invoice Number": "",
          "Client Name": "",
          "Invoice Date": "",
          "Total all-inclusive": "",
          Currency: ""
        },
        editing: {
          "Invoice Number": false,
          "Client Name": false,
          "Invoice Date": false,
          "Total all-inclusive": false,
          Currency: false
        },
        cellStyles: {
          Reference: {
            backgroundColor: "",
          },
  
          Supplier: {
            backgroundColor: "",
          },
  
          Amount: {
            backgroundColor: "",
          },
  
          "Regulation Date": {
            backgroundColor: "",
          },
  
          "Deadline Date": {
            backgroundColor: "",
          },
        },
  
        mobileBreakpoint: 768,
        isSidebarOpen: false,
        listeStock: [],
        idSoc: "S123",
        uploadedFile: null,
        uploadedFileUrl: null,
        isImage: false,
        isLoading: false,
        imageUrl: "https://finex.4help.tn/",
  
  
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "",
        sortDesc: false,
        fields: [
  
          {
            key: "Reference",
            sortable: true,
          },
          {
            key: "Supplier",
            sortable: true,
          },
          {
            key: "Amount",
            sortable: true,
          },
          {
            key: "Regulation Date",
            sortable: true,
          },
          {
            key: "Deadline Date",
            sortable: true,
          }
        ],
      };
    },
    components: {
      Layout,
      Multiselect,

    },
    async mounted() {
      await this.getSuppliers()
      await this.getBankRegulations()
    },
    created() {
    },
    computed: {
      sidebarState() {
        return this.isSidebarOpen ? "open" : "closed";
      },
      rows() {
        return this.listeRglement.length;
      },
    },
  
    methods: {

 

      async getSuppliers() {
            this.Suppliers = await axios.get('https://finex.4help.tn/api/v1/getsuppliers')
            .then(res => { return res.data.map(supplier => supplier["Supplier Name"]) })

        },



      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async getBankRegulations() {
        this.listeRglement = await axios.get("https://finex.4help.tn/api/v1/getReglements").then(res => { return res.data })
        console.log(this.listeRglement)
      },
      initProdObj() {
        this.InvoiceVariables.products.push({
          produit: { value: "", editing: false },
          designation: { value: "", editing: false },
          quantity: { value: "", editing: false },
          totalHT: { value: "", editing: false },
          tva: { value: "", editing: false },
          totalTTC: { value: "", editing: false }
        })
      },
  
      showPopup() {
        this.isPopupVisible = true;
      },
      hidePopup() {
        this.isPopupVisible = false;
      },
  
  
  
      startEditing(cellKey) {
        this.editing[cellKey] = true;
      },
      stopEditing(cellKey) {
        this.editing[cellKey] = false;
      },
      startEditingProd(cellKey, field) {
        this.InvoiceVariables.products[cellKey][field].editing = true;
      },
      stopEditingProd(cellKey, field) {
        this.InvoiceVariables.products[cellKey][field].editing = false;
      },
      onDrop(dataKey, event) {
        event.preventDefault();
        const word = event.dataTransfer.getData("text/plain");
        this.InvoiceVariables[dataKey] = word;
        this.cellStyles[dataKey].backgroundColor = "#ff4e00";
        setTimeout(() => {
          this.cellStyles[dataKey].backgroundColor = "transparent";
        }, 500);
      },
  
      onDropProduct(dataKey, Elem, event) {
        event.preventDefault();
        const word = event.dataTransfer.getData("text/plain");
        const field = (Elem.split('_'))[0]
        this.InvoiceVariables.products[dataKey][field].value = word;
        this.$refs[Elem][0].style.backgroundColor = "#ff4e00";
        setTimeout(() => {
          this.$refs[Elem][0].style.backgroundColor = "transparent";
        }, 500);
      },
  
      onDragStartCell(cellKey, event) {
        //alert(this.InvoiceVariables[cellKey]);
        event.dataTransfer.setData("text/plain", this.InvoiceVariables[cellKey]);
        this.InvoiceVariables[cellKey] = "";
      },
  
      onDragStartCellProd(cellKey, Elem, event) {
        //alert(this.InvoiceVariables[cellKey]);
        event.dataTransfer.setData("text/plain", this.InvoiceVariables.products[cellKey][Elem].value);
        this.InvoiceVariables.products[cellKey][Elem].value = "";
      },
  
      onDragStart(index, event) {
        event.dataTransfer.setData(
          "text/plain",
          this.Splitted_Words_from_text[index]
        );
      },
      onDragOver(event) {
        event.preventDefault();
      },
      onDragEnd() { },
  
      initInvoiceVars() {
        this.InvoiceVariables = {
          "Regulation Date": "",
          "Client Name": "",
          "Regulation Number": "",
          "Total all-inclusive": "",
          Currency: "",
        };
        this.uploadedFile = null;
        this.uploadedFileUrl = null;
        this.isImage = false;
        this.isLoading = false;
      },
  
      selectAndUploadFile() {
  
        this.initInvoiceVars()
  
        const input = document.createElement("input");
        input.type = "file";
        input.accept = ".pdf, .xlsx , .csv";
        input.style.display = "none";

        const acceptedTypes = ['application/pdf', 'application/vnd.ms-excel', 'text/csv'];

        input.addEventListener("change", (event) => {
          const selectedFile = event.target.files[0];
          if (selectedFile) {
            this.uploadedFile = selectedFile;
            this.isImage = acceptedTypes.includes(this.uploadedFile.type);
            // console.log(this.uploadedFile,this.isImage)
            this.uploadFile();
            console.log(this.uploadedFile)
          }
        });
  
        input.click();
      },
  
      async uploadFile() {
        try {
          const formData = new FormData();
          formData.append("pdfFile", this.uploadedFile);
          console.log(this.uploadedFile);
          this.isLoading = true;
  
          try {
            const response = await axios.post(
              "https://finex.4help.tn/api/v1/parse-file",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data", // Add this header for file upload
                },
              }
            ).then((res)=>{
              this.isLoading=false
                console.log(res)
                if(res.data.message){
                  console.log('success')
                  AddingInvoice=false
                  swal({
                    title: 'Succès',
                    text: 'regulation Successfully added',
                    icon: 'success',
                  });
                }else {
                  console.log('error')
                  swal({
                    title: 'info',
                    text: 'all regulation already exists',
                    icon: 'info',
                  });
                }
            })
            this.uploadedFileText = response.data.extractedText;
  
            
                    } catch (error) {
                      console.error("Error submitting form:", error);
                    }
                  } catch (error) {
                    console.error("Error:", error);
                  }
                },



            // (this.isLoading = false),
            //   (this.uploadedFileUrl =
            //     this.imageUrl +
            //     "uploads/" +
            //     this.uploadedFile.name);
  
            // console.log(this.uploadedFileUrl)
  
            
            // //calling NLP functions
            // this.extractNumFacture(this.uploadedFileText)
            // this.extracDateFacture(this.uploadedFileText)
            // this.extractClientName(this.uploadedFileText)
            // this.extractTotalTTC(this.uploadedFileText)
            // this.extractCurrency(this.uploadedFileText)
  
            // this.Splitted_Words_from_text = this.uploadedFileText.split(/\s+/);
            // console.log(this.Splitted_Words_from_text);
  
  
  
            // alert(this.Splitted_Words_from_text[0]);
  
    //   async EnregistrerProduit() {
    //     console.log(this.InvoiceVariables)
    //     axios.post("https://finex.4help.tn/api/v1/addpruchaseinvoice", this.InvoiceVariables).then(async (res) => {
    //       if (res.status === 200) {
    //         swal({
    //           title: 'Succès',
    //           text: 'Nouveau facture ajouter!',
    //           icon: 'success',
    //         });
  
    //         await this.getFactureAchat()
    //         this.AddingInvoice = false
    //         this.initInvoiceVars()
  
    //       } else {
    //         swal({
    //           title: 'Error',
    //           text: `Erreur du serveur.`,
    //           icon: 'success',
    //         });
    //       }
    //     })
    //     this.showPopup = true;
  
    //     this.isPopupVisible = true;
    //   },
  
      extractCurrency(inputText) {
        const currencyRegex = /(?:devise|currency)\s*:\s*(\S+)/i;
        const match = inputText.match(currencyRegex);
        console.log(match)
        if (match) {
          this.InvoiceVariables.Currency = match[1].toUpperCase(); // Store the matched word in lowercase
        }
      },
  
      extracDateFacture(inputText) {
        const referenceRegex = /\b(date)\b\s*[:]?[ \/-]?(\d+)[ \/-](\d+)[ \/-](\d+)/i
        const match = inputText.match(referenceRegex);
        console.log(match)
        if (match && match[2] && match[3] && match[4]) {
          this.InvoiceVariables['Invoice Date'] = `${match[2]}/${match[3]}/${match[4]}`;
        }
      },
  
  
      async extractNumFacture(inputText) {
        const referenceRegex = /FACTURE\s*(N°|#)?\s*([a-zA-Z0-9-]+)/i
        const match = inputText.match(referenceRegex);
        console.log(match)
        if (match) {
          this.InvoiceVariables["Invoice Number"] = match[2]; // Assuming the invoice number is captured at index 7
        }
      },
  
      extractClientName(text) {
        const lines = text.split('\n'); // Split text into lines
        this.InvoiceVariables['Client Name'] = lines[0].trim(); // Extract the first line and remove leading/trailing spaces
      },
  
      extractTotalTTC(text) {
        const regex = /\bTOTAL\s+TTC\s*[:]?[ \/-]?([0-9,.]+)/i;
        const match = text.match(regex);
  
        if (match && match[1]) {
          this.InvoiceVariables['Total all-inclusive'] = match[1].replace(/,/g, ''); // Remove commas from the matched number
        }
        console.log(this.InvoiceVariables['Total all-inclusive'])
      },
  
      preventsubmit(e) {
        e.preventDefault()
        },




      handleSumbit() {
        const regulationDate = new Date(this.form['Regulation Date']);
        const deadlineDate = new Date(this.form['Deadline Date']);
          console.log(this.form.Amount>0)
          if(this.form.Amount>0 && regulationDate<deadlineDate){
      
            axios.post("https://finex.4help.tn/api/v1/addReglement", this.form).then(async (res) => {
              console.log(res)
          if (res.status === 200) {
            swal({
              title: 'Succès',
              text: 'Nouveau reglement ajouter!',
              icon: 'success',
            });
  
            await this.getBankRegulations()
            this.AddingInvoice = false
            this.initInvoiceVars()
          }else if( res.status === 409 ){
            console.log(res.status)
          } else {
            swal({
              title: 'Error',
              text: `Erreur du serveur.`,
              icon: 'success',
            });
          }
        })
        }
        },

    },
    toggleSidebar() {
      this.isOpen = !this.isOpen;
  
      if (this.isSidebarOpen) {
        this.isSidebarOpen = false;
      } else {
        this.isSidebarOpen = true;
      }
    },

  };
  </script>
  <style scoped>
  .card {
    min-height: 100%;
    margin-bottom: 0px !important;
  }
  
  .word-container {
  
    margin: 10px;
    padding: 5px;
    padding-top: 25px;
    background-color: #f0f0f0;
  }
  
  .draggable-word {
    margin: 5px;
  }
  
  .draggable-word:hover {
    background: #ccc;
    border-radius: 5%;
    cursor: pointer;
  }
  
  .TableInfo {
    width: 100%;
    height: fit-content;
  }
  
  .loading-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
  }
  
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  .file-upload-button {
    background-color: #082748;
    color: #fff;
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease;
  }
  
  .file-upload-button:hover {
    background-color: #0056b3;
  }
  
  .file-download-button {
    background-color: #dd4b0c;
    color: #fff;
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease;
  }
  
  .file-download-button:hover {
    background-color: #f8773f;
  }
  
  .uploaded-file {
    margin-top: 20px;
  }
  
  .uploaded-image {
    height: 70vh;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .uploaded-pdf {
    width: 100%;
    height: 500px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .accueil {
    text-align: center;
    padding: 3rem;
    justify-content: center;
    align-items: center;
  }
  
  
  
  @media (max-width: 768px) {
    .accueil {
      padding: 20px;
    }
  
  
    h1 {
      font-size: 20px;
      margin-bottom: 15px;
    }
  
    h2 {
      font-size: 16px;
      margin-bottom: 10px;
    }
  
    p {
      font-size: 12px;
    }
  
  
  }
  
  
  th,
  td {
    padding: 12px 15px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
    font-weight: bold;
    color: #333;
  }
  
  tr {
    height: 50px;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  tr:hover {
    background-color: #e0e0e0;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  @media (max-width: 768px) {
    table {
      font-size: 14px;
    }
  }
  
  .form-container {
    margin: 20px;
    margin-top: -20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 95%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  
  h1 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  
  
  label {
    font-weight: bold;
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  input[type="text"] {
    background: #fff;
    /* border: none; */
    height: 100%;
  }
  
  table {
    width: 60%;
    border-collapse: collapse;
    margin-top: 20px;
    height: 500px;
  }
  
  th,
  td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
    transition: background-color 0.5s;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  tr.custom-height {
    min-height: 1rem;
    /* Change this value as needed */
  }
  
  td input {
    width: 100% !important;
  }
  </style>
  